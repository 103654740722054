<template>
    <NuxtImg v-bind="props" :provider="provider" class="aspect-auto zoom-image" @click="open" />
</template>

<style>
.zoom-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity .3s;
    will-change: opacity;
    background: rgba(255, 255, 255, 0.5);
    z-index: 20;
}

.zoom-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 3rem;
    border: 0.5rem solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    border-top: 0.5rem solid #1c1cf0;
    z-index: 20;
    animation: rotating 1.5s ease-in-out infinite;
    will-change: transform, opacity;
    transition: opacity 0.3s;
}

@keyframes rotating {
    0% {
        transform: translate(-50%, -50%) rotate(0deg)
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.zoom--opened .zoom-overlay {
    cursor: pointer;
    cursor: zoom-out;
    opacity: 1
}

.zoom-image {
    cursor: pointer;
    cursor: zoom-in;
    z-index: 21;
    transition: transform .3s cubic-bezier(.2, 0, .2, 1) !important
}

.zoom-image--hidden {
    visibility: hidden
}

.zoom-image--opened {
    position: relative;
    cursor: pointer;
    cursor: zoom-out;
    object-fit: contain;
    will-change: transform;
}
</style>

<script setup lang="ts">
const props = defineProps({
    src: String,
    alt: String,
    height: String,
    width: String,
    href: String,
    quality: String,
    loading: String,
    class: String,
    title: Boolean,
    provider: String
})

const image = ref(null)
const zoomed = ref(null)
const overlay = ref(null)

const scrollTop = ref(0)

const open = async (ev) => {
    image.value = ev.target
    overlay.value = document.createElement("div")
    overlay.value.classList.add("zoom-overlay")
    const loading = document.createElement("div")
    loading.classList.add("zoom-loading")
    overlay.value.appendChild(loading)
    document.body.appendChild(overlay.value)
    window.requestAnimationFrame(() => {
        document.body.classList.add("zoom--opened")
    })
    scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

    // Load original image
    try {
        await loadImage(image.value.src)
        createZoomed(zoomed.value)
    } catch (err) {
        console.error(err)
    } finally {
        loading.style.opacity = "0"
        await new Promise(resolve => setTimeout(resolve, 0))
        image.value.classList.add("zoom-image--hidden")
        zoomed.value.classList.add("zoom-image")
        zoomed.value.classList.add("zoom-image--opened")
        zoomed.value.addEventListener("click", close, true)
        overlay.value.addEventListener("click", close, true)
        document.addEventListener("keyup", handleKeyUp)
        document.addEventListener("scroll", handleScroll)
        window.addEventListener("resize", close)
    }
}
const handleKeyUp = (event) => {
    const key = event.key || event.keyCode;
    if (key === "Escape" || key === "Esc" || key === 27) {
        close()
    }
}
const handleScroll = () => {
    const currentScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (Math.abs(scrollTop.value - currentScroll) > 200) {
        setTimeout(close, 150);
    }
}
const close = () => {

    if (zoomed.value) {
        zoomed.value.style.transform = ""
    }
    setTimeout(() => {
        if (image.value) {
            image.value.classList.remove("zoom-image--hidden")
            image.value = null
        }
        if (zoomed.value) {
            zoomed.value.removeEventListener("click", close)
            document.body.removeChild(zoomed.value)
            zoomed.value = null
        }

        if (overlay.value) {
            overlay.value.removeEventListener("click", close)
            document.body.removeChild(overlay.value)
            overlay.value = null
        }

        document.removeEventListener("keyup", handleKeyUp)
        document.removeEventListener("scroll", handleScroll)
        window.removeEventListener("resize", close)
    }, 300)
}

const createZoomed = (zoomImage) => {
    const originalRect = image.value.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0
    zoomImage.style.position = "absolute"
    zoomImage.style.top = originalRect.top + scrollTop + "px"
    zoomImage.style.left = originalRect.left + scrollLeft + "px"
    zoomImage.style.width = originalRect.width + "px"
    zoomImage.style.height = originalRect.height + "px"
    zoomImage.style.transform = ""

    document.body.appendChild(zoomImage)
    const viewportWidth = document.documentElement.clientWidth - 30 * 2
    const viewportHeight = document.documentElement.clientHeight - 30 * 2
    const naturalWidth = zoomImage.naturalWidth || viewportWidth
    const naturalHeight = zoomImage.naturalHeight || viewportHeight
    const imageRect = zoomImage.getBoundingClientRect()
    const top = imageRect.top
    const left = imageRect.left
    const width = imageRect.width
    const height = imageRect.height
    const scaleX = Math.min(Math.max(width, naturalWidth), viewportWidth) / width
    const scaleY = Math.min(Math.max(height, naturalHeight), viewportHeight) / height
    const scale = Math.min(scaleX, scaleY)
    const translateX = (-left + (viewportWidth - width) / 2 + 30) / scale
    const translateY = (-top + (viewportHeight - height) / 2 + 30) / scale
    const transform = "scale(" + scale + ") translate3d(" + translateX + "px, " + translateY + "px, 0)"
    zoomImage.style.transform = transform
}


const loadImage = (url) => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        if (props.provider) {
            image.src = url
        } else {
            image.src = "/assets/" + url.split('/assets/')[1]
        }
        image.onload = () => {
            zoomed.value = image
            resolve(image)
        }
        image.onerror = () => {
            reject(new Error("Failed to load image."))
        }
    })
}
</script>
